// extracted by mini-css-extract-plugin
export var container = "AboutUs-module--container--HjT4m";
export var main = "AboutUs-module--main--qAHiR";
export var main__heading = "AboutUs-module--main__heading--P424e";
export var main__information = "AboutUs-module--main__information--4l58O";
export var main__text = "AboutUs-module--main__text--4vWyi";
export var wrapper = "AboutUs-module--wrapper--Obmz6";
export var wrapper__header = "AboutUs-module--wrapper__header--HsHoQ";
export var wrapper__header__media = "AboutUs-module--wrapper__header__media--+I0Gj";
export var wrapper__header__media__footer = "AboutUs-module--wrapper__header__media__footer--NzF4D";
export var wrapper__header__media__footer_icon = "AboutUs-module--wrapper__header__media__footer_icon--Wth5g";
export var wrapper__header__media__footer_text = "AboutUs-module--wrapper__header__media__footer_text--In4DR";
export var wrapper__header__media__video = "AboutUs-module--wrapper__header__media__video--djmpU";
export var wrapper__header__text__heading = "AboutUs-module--wrapper__header__text__heading--nNyL2";
export var wrapper__header__text__inner = "AboutUs-module--wrapper__header__text__inner--5cald";
export var wrapper__header__text__inner__icon = "AboutUs-module--wrapper__header__text__inner__icon--KQtd6";