import React, { memo } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Icon } from 'modules';
import { andSignIcon, verticalLine } from 'assets/svg';
import * as styles from './AboutUs.module.scss';

function AboutUs(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <header className={styles.wrapper__header}>
          <div className={styles.wrapper__header__text}>
            <h1 className={styles.wrapper__header__text__heading}>extremely</h1>
            <h1 className={styles.wrapper__header__text__heading}>loud</h1>
            <div className={styles.wrapper__header__text__inner}>
              <Icon
                viewBox={andSignIcon.viewBox}
                path={andSignIcon.path}
                className={styles.wrapper__header__text__inner__icon}
              />
              <h1 className={styles.wrapper__header__text__heading}>
                incredibly
              </h1>
            </div>
            <h1 className={styles.wrapper__header__text__heading}>close</h1>
          </div>
          <div className={styles.wrapper__header__media}>
            <video
              className={styles.wrapper__header__media__video}
              muted
              loop
              autoPlay
            >
              <source
                src={'/mainPageVideos/mainPageVideo.mp4'}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <div className={styles.wrapper__header__media__footer}>
              <Icon
                viewBox={verticalLine.viewBox}
                path={verticalLine.path}
                className={styles.wrapper__header__media__footer_icon}
              />
              <h6 className={styles.wrapper__header__media__footer_text}>
                {t('common:about')}
              </h6>
            </div>
          </div>
        </header>
        <main className={styles.main}>
          <h6 className={styles.main__heading}>
            {t('aboutUs:info.first_text')}
          </h6>
          <h6 className={styles.main__information}>
            {t('aboutUs:info.second_text')}
          </h6>
          <h6 className={styles.main__text}>{t('aboutUs:info.third_text')}</h6>
          <h6 className={styles.main__information}>
            {t('aboutUs:info.forth_text')}
          </h6>
          <h1 className={styles.main__heading}>
            {t('aboutUs:info.satisfied')}
          </h1>
        </main>
      </div>
    </div>
  );
}

export default memo(AboutUs);
